<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				SKU拆分
			</h3>
		</div> -->
		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span class="tct_tit">三方应用授权</span>
						<!-- <el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini"
								@click="exportExcelAction()"></el-button>
						</el-tooltip> -->
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <ul class="actionConList"> -->
						<!-- <li> -->
							<el-button type="success" @click="openAuth()" size="small" icon="el-icon-plus">
								<span>TIKTOK</span>
								<span>授权</span>
							</el-button>
						<!-- </li> -->
					<!-- </ul> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<!-- <div class="filterBarList">
				<el-menu :default-active="filterData.status" mode="horizontal" @select="handleSelectStatus">
					<el-menu-item :index="item.value" v-for="item in selectOption.statusList" :key="item.value">
						<span>{{ item.label }}</span>
					</el-menu-item>
				</el-menu>
			</div> -->
			<div class="filterAction">
				<!-- <ul class="actionConList">
					<li>
						<el-button type="success" @click="openNew()" size="small" icon="el-icon-plus">{{$t('i18nn_a6abf1dd9e065e27')}}</el-button>
					</li>
				</ul> -->
				<!-- <ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo2"></whNoSelect>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" @keyup.enter.native="initData()" style="width: 250px;">
						</el-input>
					</li>

					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					
				</ul> -->
			</div>
		</div>

		<!-- <div class="wh-filter-con">
			<div class="">
			  <span>{{$t('Storage.packing_list.status')}}</span>
				<el-radio-group v-model="filterData.status" size="medium" @change="serPageData()">
				  <el-radio-button border v-for="item in selectOption.statusList" :key="item.value" :label="item.value">
						{{item.label}}
					</el-radio-button>
				</el-radio-group>
			</div>
		</div> -->
		<!-- <div class="wh-filter-con">
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
				<SelAgentUser @change="changeAgentUser"></SelAgentUser>
			</div>
			<div class="wh-filter-item">
				<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
				<whNoSelect @changeData="changWhNo2"></whNoSelect>
			</div>
			
			<div class="wh-filter-item">
				<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
				<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword" maxlength="50" :clearable="true" style="width: 150px;"></el-input>
			</div>
			<el-button type="primary" @click="serPageData" size="small">{{ $t('FormMsg.Search') }}</el-button>
		</div> -->

		<!-- <div class="wh-action-con">
			<el-button type="success" @click="openDioalog(null)" size="small" icon="el-icon-plus">{{$t('i18nn_52687a58ca0645c3')}}</el-button>
		</div> -->

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="ex_table" ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%"
				size="small" :max-height="$store.state.frameConHeightWh1">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>
					
					<!-- "confMatk": null, //"账号备注",
					"ebUrl": null, //"",
					"wmsUrl": null, //"",
					"userName": null, //"",
					"userPass": null, //"", -->


				<el-table-column prop="authAp" :label="'authAp'"></el-table-column>
				<el-table-column prop="sellerBaseRegion" :label="'sellerBaseRegion'"></el-table-column>
				<el-table-column prop="shopCipher" :label="'shopCipher'"></el-table-column>
				<el-table-column prop="shopCode" :label="'shopCode'"></el-table-column>
				<el-table-column prop="shopId" :label="'shopId'"></el-table-column>
				
				
				<el-table-column prop="shopName" :label="'shopName'"></el-table-column>
				<el-table-column prop="shopType" :label="'shopType'"></el-table-column>
				
				<el-table-column prop="tpCode" :label="'tpCode'"></el-table-column>
				
				
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="200">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>创建时间</span>:{{scope.row.createTime}}
								</li>
								<li>
									<span>更新时间</span>:{{scope.row.updateTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>

				<el-table-column :label="$t('Storage.tableColumn.operation')" width="220px" fixed="right">
					<template slot-scope="scope">

						<div>
							<!-- <div>
								<el-button @click="openWhUpdate($event, scope.row, scope.$index)" type="primary" size="mini"
									icon="el-icon-refresh">{{$t('i18nn_44b27e0a8476a256')}}</el-button>
								
							</div> -->
							
							<!-- <el-button @click="PullOrderData($event, scope.row, scope.$index)" type="success" size="mini"
								icon="el-icon-sold-out">获取订单</el-button> -->
								
							<!-- <div style="margin-top: 10px;">
								<el-button @click="openEdit($event, scope.row, scope.$index)" type="warning" size="mini"
									icon="el-icon-edit">{{ $t('FormMsg.Edit') }}</el-button>
									
								<el-button @click="delAction($event, scope.row)" type="danger" size="mini"
									icon="el-icon-minus">{{ $t('FormMsg.Delete') }}</el-button>

							</div> -->
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!-- 第三方授权页面 -->
		<ThirdAppAuthPage :openTime="ThirdAppAuthOpenTime" @success="ThirdAppAuthBack"></ThirdAppAuthPage>
	</div>
</template>
<script>
	import ThirdAppAuthPage from '@/components/StorageCenter/ThirdApplicationAuth/ThirdAppAuthPage.vue';
	
	export default {
		
		components: {
			ThirdAppAuthPage
		},
		
		data() {
			return {

				dialogFormVisible: false,
				dialogFormStatus: 0, //0-新增，1-修改，2-更新仓库

				// loading_sel: false,
				// pullYiCangWhData: [],
				// openTimeYiCangWh:"",
				
				ThirdAppAuthOpenTime:'',

				loading: false,
				// form: {
				// 	"id": null, //"数据ID",
				// 	"confMatk": null, //this.$t('i18nn_b306361a5aeda4e8'),
				// 	"ebUrl": null, //"",
				// 	"wmsUrl": null, //"",
				// 	"userName": null, //"",
				// 	"userPass": null, //"",
				// 	"serviceToken":null,
				// 	"serviceCode":null,
				// 	"warehouseList": [

				// 	]
				// },
				// formRules: {
					
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_size_unit: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序

				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			// this.getPullYiCangWhQuery();
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},
			
			// changYiCangWh(data) {
			// 	console.log('changYiCangWh', data);
			// 	this.form.warehouseList = data;
			// },
			
			//打开新增编辑，弹窗
			// openDioalog(formParm,type) {
			// 	// console.log(formParm);
			// 	this.dialogFormVisible = true;
			// 	// this.openTimeYiCangWh = new Date().getTime();
			// 	let form = Object.assign({}, formParm);
			// 	console.log('form', form);
			// 	// // 重置
			// 	this.resetForm('form');

			// 	if (0 == type) {
			// 		//新增

			// 		// this.form.weightUnit = '1';
			// 		// this.form.volumeUnit = '1';
			// 		// this.form.whFeeType = '1';

			// 		this.dialogFormStatus = type;
			// 		// form.subUserId = null;
			// 		// form.userSubUserId = null;
			// 		// form.state = true;
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 		this.form.warehouseList = [];

			// 		// this.$nextTick(() => {
			// 		// 	this.$refs.whYiCangWhSelect.init([]);
			// 		// });
			// 	} else {
			// 		//修改，更新仓库
			// 		this.dialogFormStatus = type;
			// 		// form.state = form.state === '0' ? true : false;
			// 		// this.form.goodsSubjectId = this.CateValue[0];
			// 		// this.CateValue = [form.goodsSubjectId];
			// 		//浅拷贝、对象属性的合并
			// 		this.form = form;
			// 		// this.getDetList(this.form.id);
			// 		this.$nextTick(() => {
			// 			let wh_ids = [];
			// 			if(this.form.warehouseList && this.form.warehouseList.length>0){
			// 				wh_ids = this.form.warehouseList.map(item=>{
			// 					return item.warehouse_id;
			// 				});
			// 			}
			// 			this.$refs.whYiCangWhSelect.init(wh_ids);
			// 		});
			// 	}
			// 	// this.$alert('确定要确认通过该笔账单！', this.$t('tips.tipsTitle'), {
			// 	//       type: 'warning',
			// 	//       //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 	//     });
			// },
			openAuth() {
				// event.stopPropagation();
				this.ThirdAppAuthOpenTime = new Date().getTime();
				// this.openDioalog(null,0);
			},
			ThirdAppAuthBack(){
				this.initData();
			},
			
			//打开编辑
			// openEdit(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openTimeYiCangWh = new Date().getTime();
			// 	this.openDioalog(row,1);
			// },
			//打开仓库更新
			// openWhUpdate(event, row, index) {
			// 	event.stopPropagation();
			// 	this.openTimeYiCangWh = new Date().getTime();
			// 	this.openDioalog(row,2);
			// },
			//删除
			// delAction(event, row) {
			// 	this.$confirm(this.$t('FormMsg.confirm_Delete'), this.$t('tips.tipsTitle'), {
			// 			// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 			type: 'warning'
			// 		})
			// 		.then(() => {
			// 			this.delDataAction(event, row);
			// 		})
			// 		.catch(() => {});
			// },
			//删除
			// delDataAction(event, row) {
			// 	event.stopPropagation();
			// 	console.log('delDataAction', row);

			// 	this.postData(this.$urlConfig.WhThirdYiCangConfDel + '/' + row.id, {}, 'delete');
			// },

			//取消拆分
			// cancelAction(event, row) {
			// 	this.$confirm('确定取消拆分吗？', this.$t('tips.tipsTitle'), {
			// 		// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhSkuSplitCancel + '/' + row.id, {}, 'put');
			// 		})
			// 		.catch(() => {});
			// },
			//提交
			// submitlAction(event, row) {
			// 	this.$confirm('确定提交吗？', this.$t('tips.tipsTitle'), {
			// 		// confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 		// cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			// 		type: 'warning'
			// 	})
			// 		.then(() => {
			// 			this.postData(this.$urlConfig.WhSkuSplitSubmit + '/' + row.id, {}, 'put');
			// 		})
			// 		.catch(() => {});
			// },

			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);
			// 			//浅拷贝、对象属性的合并
			// 			if (0 == this.dialogFormStatus) {//新增
			// 				formData.id = null;
			// 				// formData.userId = this.UserInfo.id;
			// 				this.postData(this.$urlConfig.WhThirdYiCangConfAdd, formData);
			// 			} else if(1==this.dialogFormStatus) {//编辑
			// 				this.postData(this.$urlConfig.WhThirdYiCangConfAdd, formData);
			// 			} else {//更新仓库
			// 				let formDataUpdateWh = {
			// 					id: formData.id,
			// 					warehouseList: formData.warehouseList
			// 				};
			// 				// formData.userId = this.UserInfo.id;
			// 				this.postData(this.$urlConfig.WhThirdYiCangConfUpdate, formDataUpdateWh);
			// 			}
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },
			//重置输入框
			// resetForm(formName) {
			// 	console.log(formName);
			// 	// console.log(this.$refs[formName]);

			// 	if (this.$refs[formName]) {
			// 		this.$refs[formName].resetFields();
			// 	} else {
			// 		console.log('this.$refs[formName]', this.$refs[formName]);
			// 	}
			// 	// this.CateValue = [];
			// 	// if (this.$refs.hyUpLoadImg1) {
			// 	// 	this.$refs.hyUpLoadImg1.initUrl('');
			// 	// }
			// },

			//提交信息
			postData(url, formData, type) {
				// let _this = this;
				this.loading = true;
				this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);
					
					this.loading = false;
					this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						this.initData();
						// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
						//   type: 'success',
						//   //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						// });
						// this.$message.success(this.$t('tips.submitSuccess'));
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							// this.$alert(this.$t('tips.submitSuccess'), this.$t('tips.tipsTitle'), {
							// 	type: 'success'
							// });
							this.$message.success(this.$t('tips.submitSuccess'));
						}
					} else {
						// if (!data.msg) {
						//   data.msg = this.$t('tips.submitError');
						// }
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
							//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},
			
			// openRechargeAdd() {
			// 	this.openRechargeAddTime = new Date().getTime();
			// },
			
			// clearFile() {
			// 	this.fileExcel = null;
			// 	this.excelFileName = '';
			// 	// this.excelFileName = "";
			// 	this.$nextTick(() => {
			// 		try {
			// 			this.$refs.file.value = '';
			// 		} catch (e) {
			// 			console.log(e);
			// 			//TODO handle the exception
			// 		}
			// 	});
			// },

			// 新建-表格-拆分
			// addSplit() {
			// 	this.form.recordLists.push({
			// 		goodsSku: '', //"分拆后的SKU",
			// 		breakQ: 1 //this.$t('i18nn_382a3d27fb8dc70a'),
			// 		// "id":"数据ID"
			// 	});
			// },
			// //删除-表格-拆分
			// delSplit(event, row, index) {
			// 	this.form.recordLists.splice(index, 1);
			// },
			//打开选择数据 要分拆
			// openMainSelData() {
			// 	this.dialogSelVisible = true;
			// 	this.dialogSelType = 1;
			// },
			//打开选择数据  分拆后
			// openSelData(event, row, index) {
			// 	event.stopPropagation();

			// 	this.dialogSelVisible = true;
			// 	this.dialogSelType = 2;
			// 	this.openRowData = row;
			// 	this.openRowIndex = index;
			// 	// this.$nextTick(() => {
			// 	//   this.$refs.hyUpLoadImg1.openFile();
			// 	// });
			// },
			//选择数据后回调
			// selWhGoodsData(selRow) {
			// 	this.dialogSelVisible = false;
			// 	console.log('selRow', selRow);
			// 	if (1 == this.dialogSelType) {
			// 		this.form.goodsSku = selRow.goodsSku;
			// 	} else {
			// 		let row = this.openRowData;
			// 		// row.putWhNo = selRow.putWhNo;
			// 		// row.plPlaceNo = selRow.placeNo;
			// 		row.goodsSku = selRow.goodsSku;
			// 		// row.sendAcount = 0;
			// 		// row.mySkuDto = selRow;

			// 		this.$set(this.form.recordLists, this.openRowIndex, row);
			// 	}
			// },

			// handleSelectStatus(key, keyPath) {
			// 	// console.log(key, keyPath);
			// 	this.filterData.status = key;
			// 	this.initData();
			// },

			//分页的筛选项数据
			pageFilterData() {
				return {
					// proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// status: this.filterData.status ? this.filterData.status : null,
					// whNo: this.filterData.whNo ? this.filterData.whNo : null,
					// // subjectName: this.filterData.subjectName ? this.filterData.subjectName : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// // goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// // goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					// keyword: this.filterData.keyword ? this.filterData.keyword : null
					// // declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},
			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);

				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhThirdShopPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			
			//获取订单
			PullOrderData(){
				
			},
			//明细
			// getDetList(breakId) {
			// 	this.loading = true;
			// 	let HttpType = {};
			// 	HttpType = this.$http.get(this.$urlConfig.WhSkuSplitDetList + '/' + breakId, {});
			// 	HttpType.then(({
			// 		data
			// 	}) => {
			// 		console.log(data);
			// 		this.loading = false;
			// 		if (200 == data.code) {
			// 			this.form.recordLists = data.rows;
			// 		} else {
			// 			this.$alert(data.msg ? data.msg : this.$t('i18nn_d74d0bd89431d6d5'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 			});
			// 		}
			// 	}).catch(error => {
			// 		console.log(error);
			// 		console.log(this.$t('tips.submitError'));
			// 		this.loading = false;
			// 		this.$alert(this.$t('i18nn_d74d0bd89431d6d5'), this.$t('tips.tipsTitle'), {
			// 			type: 'warning'
			// 		});
			// 	});
			// },
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//易仓拉取查询-仓库
			// getPullYiCangWhQuery() {
			// 	// let _this = this;
			// 	this.loading_sel = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhThirdYiCangWhQuery, {})
			// 		.then(({
			// 			data
			// 		}) => {
			// 			// console.log(this.$store.getters.getUserInfo);
			// 			console.log('易仓拉取查询-仓库');
			// 			console.log(data);
			// 			
			// 			this.loading_sel = false;
			// 			this.pullYiCangWhData = data.data;

			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			// console.log('分页，请求失败');
			// 			this.loading_sel = false;
			// 		});
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>
