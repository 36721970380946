<template>
	<div class="">

		<!-- 修改弹窗 -->
		<el-dialog :close-on-click-modal="false" :title="'TIKTOK授权'" append-to-body :visible.sync="dialogFormVisible">
			<el-form ref="form" :rules="formRules" :size="''" :model="form" label-width="150px" v-loading="loading"
				:element-loading-text="$t('i18nn_1b4525c800280581')">

				<el-form-item :label="'tpCode'">
					<el-select filterable clearable v-model="form.tpCode" :placeholder="$t('2ad108ab2c560530')"
						@change="tpCodeChange()" style="width: 200px;">
						<el-option v-for="item in selectOption.lgs_auth_tp_code" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</el-form-item>


				<el-descriptions title="授权配置信息" v-loading="loading">
					<el-descriptions-item label="apCode">
						{{TTPAuthConfigData.apCode}}
					</el-descriptions-item>
					<el-descriptions-item label="apName">
						{{TTPAuthConfigData.apName}}
					</el-descriptions-item>
					<el-descriptions-item label="tpCOde">
						{{TTPAuthConfigData.tpCOde}}
					</el-descriptions-item>
					<el-descriptions-item label="authUrl">
						{{TTPAuthConfigData.authUrl}}
					</el-descriptions-item>
				</el-descriptions>

				<!-- <el-form-item :label="$t('i18nn_a06e84801d6582be')" prop="amt">
					<el-input-number v-model="form.amt" :precision="2" style="width: 200px;"></el-input-number>
					<span class="form_msg">USD</span>
				</el-form-item> -->

				<!-- <el-form-item :label="$t('i18nn_8c49a4bfd3a78dc2')" prop="" required>
					<div>
						<RechargeRecordFileUpload ref="RechargeRecordFileUpload" :openTime="FileUploadOpenTime"  @success="FileUploadSuccess"></RechargeRecordFileUpload>
					</div>
				</el-form-item> -->

				<!-- <el-form-item :label="$t('Storage.tableColumn.remark')">
					<el-input type="textarea" :placeholder="$t('FormMsg.Please_Enter')" v-model="form.remark" :maxlength="1000"
						show-word-limit style="width: 300px;"></el-input>
				</el-form-item> -->
			</el-form>

			<div slot="footer" class="dialog-footer">
				<!-- <el-button type="primary" plain @click="dialogFormVisible = false">{{ $t('FormMsg.Close') }}</el-button> -->
				<!-- <el-button type="primary" icon="el-icon-tickets"
					@click="submitForm('form')">{{ $t('FormMsg.Save') }}</el-button> -->
				<a :href="TTPAuthConfigData.authUrl" target="_blank" v-if="TTPAuthConfigData.authUrl" style="padding: 0 10px;">
					<el-button type="primary" icon="el-icon-right">去授权</el-button>
				</a>
				
				<el-button type="warning" icon="el-icon-refresh-right"
					@click="toRefreshShop()">刷新店铺</el-button>
			</div>
		</el-dialog>

	</div>
</template>
<script>
	// import RechargeRecordFileUpload from '@/components/StorageCenter/components/RechargeRecordFileUpload.vue';
	import {
		getDicData
	} from '@/axios/common.js';

	import {
		clearObjectVal
	} from '@/utils/common.js';


	export default {

		components: {
			// RechargeRecordFileUpload
		},
		props: {
			openTime: {

			},
			row: {},
		},
		data() {
			return {

				dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				// CateValue: [],
				loading: false,

				TTPAuthConfigData: {},

				form: {
					// amt: '', //this.$t('i18nn_a06e84801d6582be'),
					// evidenceList: [],
					tpCode: '',
					remark: ''
				},

				formRules: {
					// amt: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }],
					// remark: [{
					// 	required: true,
					// 	message: this.$t('FormMsg.Please_Enter'),
					// 	trigger: 'blur'
					// }]
				},

				// loading_load: false,
				// tableData: [],
				//分页数据
				// pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_weight_unit: [],
					// wh_vol_unit: [],
					lgs_auth_tp_code: []
				},
				//查询，排序方式
				// filterData: {
				// 	orderBy: 'id_', //排序字段
				// 	sortAsc: 'desc', //desc降序，asc升序

				// 	agentUser: '',
				// 	status: '',
				// 	keyword: ''
				// }
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime', newVal);
				this.dialogFormVisible = true;
				this.initData();

			}
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			//数据字典
			getDicData(['lgs_auth_tp_code'],
				(data) => {
					this.selectOption.lgs_auth_tp_code = data['lgs_auth_tp_code'];
				});

		},
		methods: {
			initData() {
				this.openDioalog(null);
			},

			//打开新增编辑，弹窗
			openDioalog(formParm) {
				// console.log(formParm);
				this.dialogFormVisible = true;
				let form = Object.assign({}, formParm);
				console.log('form', form);
				// // 重置
				this.resetForm('form');
			},

			//选择 tpCode
			tpCodeChange() {
				this.getTPCodeConfig(this.form.tpCode)
			},

			// 查询 tpCode 配置
			getTPCodeConfig(tpCode) {
				// let _this = this;
				this.loading = true;
				this.$http
					.get(this.$urlConfig.WhThirdTTPAuthConfigQuery + "?tpCode=" + tpCode, {})
					.then(({
						data
					}) => {
						this.loading = false;
						if (200 == data.code && data.data) {
							this.TTPAuthConfigData = data.data;
						} else {
							if (!data.msg) {
								data.msg = this.$t('tips.submitError');
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						console.log(error);
						// console.log('分页，请求失败');
						this.loading = false;
					});
			},

			//去授权
			toAuthAction() {
				if (this.TTPAuthConfigData.authUrl) {
					window.location.href = this.TTPAuthConfigData.authUrl;
				} else {
					this.$message.warning("请选择tpCode");
				}
			},
			
			//刷新店铺
			toRefreshShop(){
				if (!this.form.tpCode) {
					this.$message.warning("请选择tpCode");
					return;
				}
				
				this.loading = true;
				
				this.$http
					.put(this.$urlConfig.WhThirdShopByTpCode, {
						"tpCode": this.form.tpCode,
						// "authCode": this.queryData.code
					})
					.then(({
						data
					}) => {
						
						this.loading = false;
						if (200 == data.code) {
							this.$message.success('刷新店铺成功');
							this.$emit('success');
							// this.$router.push({name:"ThirdApplicationList"})
						} else {
							if (!data.msg) {
								data.msg = '刷新店铺失败';
							}
							this.$message.warning(data.msg);
						}
					})
					.catch(error => {
						// console.log(error);
						console.log(this.$t('tips.errorData'), error);
						this.loading = false;
						this.$alert('刷新店铺失败', this.$t('i18nn_cc62f4bf31d661e3'), {
							type: 'warning'
						});
					});
			},

			//提交信息
			// submitForm(formName) {
			// 	this.$refs[formName].validate(valid => {
			// 		if (valid) {
			// 			let formData = Object.assign({}, this.form);
			// 			//浅拷贝、对象属性的合并
			// 			// if (0 === this.dialogFormStatus) {
			// 			// 	formData.id = null;
			// 			// 	this.postData(this.$urlConfig.WhRechargeVerifyUpdate, formData);
			// 			// } else {
			// 			// 	this.postData(this.$urlConfig.WhRechargeVerifyUpdate, formData);
			// 			// }
			// 		} else {
			// 			console.log('error submit!!');
			// 			this.$alert(this.$t('tips.checkSubmitData'), this.$t('tips.tipsTitle'), {
			// 				type: 'warning'
			// 				//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			// 			});
			// 			return false;
			// 		}
			// 	});
			// },

			//重置输入框
			resetForm(formName) {
				console.log(formName);
				if (this.$refs[formName]) {
					this.$refs[formName].resetFields();
				} else {
					console.log('this.$refs[formName]', this.$refs[formName]);
				}
				this.form = clearObjectVal(this.form);
			},

			//提交信息
			postData(url, formData, type, callback) {
				// let _this = this;
				this.loading = true;
				// this.loading_load = true;
				let HttpType = {};
				if ('delete' == type) {
					HttpType = this.$http.delete(url, formData);
				} else {
					HttpType = this.$http.put(url, formData);
				}
				// formData.state = formData.state ? '0' : '1';
				HttpType.then(({
					data
				}) => {
					console.log(this.$t('tips.submitSuccess'));
					console.log(data);

					this.loading = false;
					// this.loading_load = false;
					if (200 == data.code) {
						this.dialogFormVisible = false;
						if ('delete' == type) {
							this.$message({
								type: 'success',
								message: this.$t('tips.successfullyDelete')
							});
						} else {
							this.$message.success(this.$t('tips.submitSuccess'));
						}
						callback();
						// this.$emit('postSuccess')
					} else {
						this.$alert(data.msg ? data.msg : this.$t('tips.submitError'), this.$t('tips.tipsTitle'), {
							type: 'warning'
						});
					}
				}).catch(error => {
					console.log(error);
					console.log(this.$t('tips.submitError'));
					this.loading = false;
					// this.loading_load = false;
					this.$alert(this.$t('tips.submitRequestError'), this.$t('tips.tipsTitle'), {
						type: 'warning'
						//confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
					});
				});
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>